import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import ContentNarrow from '@components/ContentNarrow';
import Layout from '@components/Layout';
import Seo from '@components/Seo';

import { Container } from '@elements';

import { hasImage, moduleSettings, slugifyModified, pluralize } from '@utils';

const Post = ({ data, pageContext }) => {
  const { post } = data;
  if (post) {
    const imageExists = post && post.image && hasImage(post.image);
    const blogSettings = moduleSettings('blog');

    return (
      <>
        <Seo context={pageContext} {...post.seo} />
        <Layout>
          <Container className={`${!imageExists && 'pb-0'}`}>
            <ContentNarrow className="pb-8">
              <h1 className="mb-0">{post.pageInfo.name}</h1>
              <h2 className="text-3xl mb-4 block mt-0 font-primary font-normal">
                {post.subTitle}
              </h2>
              <div
                className={`${!imageExists && 'border-b pb-6'} border-t pt-6`}
              >
                {post.author && (
                  <div
                    key={post.author._id}
                    className="flex flex-col space-y-4 mb-4 md:space-y-0 md:flex-row md:space-x-4"
                  >
                    <LinkOrSpan
                      as={blogSettings.renderAuthorPages ? '' : 'div'}
                      to={
                        post.author &&
                        post.author.pageInfo &&
                        post.author.pageInfo.slug &&
                        `/${blogSettings.baseName}/author/${post.author.pageInfo.slug.current}`
                      }
                      className="rounded-full py-2 px-3 pr-6 border no-underline flex items-center"
                      key={post.author._id}
                    >
                      {imageExists && (
                        <div className="flex-shrink-0">
                          <Img
                            className="h-10 w-10 rounded-full"
                            fluid={
                              post.author &&
                              post.author.image &&
                              post.author.image.asset &&
                              post.author.image.asset.fluid
                            }
                            alt=""
                          />
                        </div>
                      )}
                      <div className="ml-3">
                        <div
                          to={
                            post.author &&
                            post.author.pageInfo &&
                            post.author.pageInfo.slug &&
                            `/${post.author.pageInfo.slug.current}`
                          }
                          className="text-sm leading-5 text-inherit font-medium mb-0"
                        >
                          {post.author &&
                            post.author.pageInfo &&
                            post.author.pageInfo.name}
                        </div>
                      </div>
                    </LinkOrSpan>
                  </div>
                )}

                <div className="flex text-sm leading-5 text-gray-500">
                  {post.publishedAt && (
                    <div>
                      Published on:{' '}
                      <time dateTime={post.date}>{post.dateReadable}</time>
                    </div>
                  )}
                  {post.readTime && post.publishedAt && (
                    <span className="mx-1">&middot;</span>
                  )}
                  {post.readTime && <span>{post.readTime} min read</span>}
                </div>
                {blogSettings.renderCategoryPages &&
                  post?.categories?.length > 0 && (
                    <div className="block text-sm">
                      {pluralize(post.categories, 'Categor', 'y', 'ies')}:{' '}
                      {post.categories.map((category, i) => (
                        <React.Fragment key={category._id}>
                          {i > 0 && ', '}
                          <Link
                            className="text-inherit"
                            to={`/${blogSettings.baseName}/category/${category?.pageInfo?.slug?.current}`}
                          >
                            {category?.pageInfo?.name}
                          </Link>
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                {blogSettings.renderTagPages && post?.tags?.length > 0 && (
                  <div className="block text-sm">
                    {pluralize(post.tags, 'Tag', '', 's')}:{' '}
                    {post.tags.map((tag, i) => (
                      <React.Fragment key={tag._id}>
                        {i > 0 && ', '}
                        <LinkOrSpan
                          className="text-inherit"
                          to={`/${blogSettings.baseName}/tag/${slugifyModified(
                            tag
                          )}`}
                          as={!blogSettings.renderTagPages && 'span'}
                        >
                          {tag}
                        </LinkOrSpan>
                      </React.Fragment>
                    ))}
                  </div>
                )}
              </div>
            </ContentNarrow>
            {imageExists && (
              <Img
                className="w-full rounded-lg shadow-2xl"
                fluid={post.image.asset.fluid}
                alt={post.image.alt}
              />
            )}
          </Container>
          <ContentNarrow className="text-lg" rawData={post._rawBody || []} />

          {/* TODO: Add related posts https://khalilstemmler.com/articles/gatsby-related-posts-component/ */}

          <ContentNarrow className="pb-10">
            <nav className="pt-10 border-t px-4 flex items-center justify-between sm:px-0">
              <div className="w-0 flex-1 flex">
                {pageContext?.prev?.node?.pageInfo?.slug?.current && (
                  <Link
                    to={`/${blogSettings.baseName}/${pageContext.prev.node.pageInfo.slug.current}`}
                    rel="prev"
                    className="btn"
                  >
                    <svg
                      className="mr-3 h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Previous Post
                  </Link>
                )}
              </div>

              <div className="w-0 flex-1 flex justify-end">
                {pageContext?.next?.node?.pageInfo?.slug?.current && (
                  <Link
                    to={`/${blogSettings.baseName}/${pageContext.next.node.pageInfo.slug.current}`}
                    rel="next"
                    className="btn"
                  >
                    Next Post
                    <svg
                      className="ml-3 h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>
                )}
              </div>
            </nav>
          </ContentNarrow>
        </Layout>
      </>
    );
  }
  return null;
};

const LinkOrSpan = styled(Link)``;

export default Post;

export const query = graphql`
  query($id: String!) {
    post: sanityPost(_id: { eq: $id }) {
      _rawExcerpt(resolveReferences: { maxDepth: 10 })
      _rawBody(resolveReferences: { maxDepth: 10 })
      author {
        _id
        pageInfo {
          name
          slug {
            current
          }
        }
        image {
          asset {
            _id
            fluid(maxWidth: 80) {
              ...GatsbySanityImageFluid
            }
          }
          alt
          caption
        }
      }
      _rawAuthor
      categories {
        _id
        pageInfo {
          slug {
            current
          }
          name
        }
      }
      image {
        asset {
          _id
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
        }
        alt
        caption
      }
      pageInfo {
        slug {
          current
        }
        name
      }
      publishedAt(formatString: "MMMM DD, YYYY")
      dateReadable: publishedAt(formatString: "MMMM DD, YYYY")
      date: publishedAt(formatString: "YYYY-MM-DD")
      seo {
        ...SeoFragment
      }
      subTitle
      readTime
      tags
    }
  }
`;
